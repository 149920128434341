/* Tailwind's base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  width: calc(200%); /* Double the width for the duplicated list */
  animation: scroll 20s linear infinite;
}

.transition-height {
  transition: max-height 0.3s ease;
}

/* Custom utility classes */
@layer utilities {
  .translate-y-full {
    transform: translateY(100%);
  }

  .translate-y-0 {
    transform: translateY(0);
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-100 {
    opacity: 1;
  }
}
